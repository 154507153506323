import { Link } from "react-router-dom";

const AllServices = () => {
    return ( 
    <div className="allServices md:px-8 py-8 ">
      
 <Link to="/" ><img src={require("../Assets/logo-no-bg.png")} className='w-32 ' /></Link>
        <div className="flex flex-wrap justify-center">
                <div id="HVAC" className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100   rounded-xl ">
                    <div className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>HVAC/R</span>
                    <img src={require("../Assets/man1.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                    <div className="flex flex-col justify-center pt-12 text-xl font-semibold gap-6">
                    <span>Duct cleaning</span>
                    <span>HVAC repairs</span>
                    <span>HVAC maintenance</span>
                    <span>Refrigeration</span>
                    </div>
                </div>
                
                <div id="Plumbing" className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100   rounded-xl ">
                      <div className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>Plumbing</span>
                    <img src={require("../Assets/Plumbing.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                    <div className="flex flex-col justify-center pt-12 text-xl font-semibold gap-6">
                    <span>Grease Traps</span>
                    <span>Drain Cleaning</span>
                    <span>Backflow</span>
                    <span>Leaking Repairs</span>
                    </div>
                  
                </div>
                <div id="Electrical" className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100   rounded-xl ">
                    <div className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>Electrical</span>
                    <img src={require("../Assets/Electrical.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                    <div className="flex flex-col justify-center pt-12 text-xl font-semibold gap-6">
                    <span>Signage</span>
                    <span>Interior Lighting Maintenance</span>
                    <span>Exterior Lighting Maintenance </span>
                    <span>Pole Light Maintenance</span>
                    <span>Generator</span>
                    </div>
                </div>

                <div id="DoorGlass" className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100   rounded-xl ">
                      <div className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>Door/Glass</span>
                    <img src={require("../Assets/glass.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                    <div className="flex flex-col justify-center pt-12 text-xl font-semibold gap-6">
                    <span>Doors Interior</span>
                    <span>Commercial Doors</span>
                    <span>Boardups</span>
                    <span>Glass replacement</span>
                    </div>
                  
                </div>
                <div id="General" className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-12/12 w-100  rounded-xl ">
                    <div className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>General Maintenance</span>
                    <img src={require("../Assets/Power washing.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                    <div className="flex flex-col justify-center pt-12 pr-14  text-xl font-semibold gap-6">
                         <span> Handyman</span>
                         <span> Landscape Services</span>
                         <span> Janitorial</span>
                         <span> Emergency Services and Restoration</span>
                         <span> Parking Lot Sweeping</span>
                         <span> Parking Lot Maintenance</span>
                         
                    </div>
                    <div className="flex flex-col justify-center lg:pt-12 pr-14  text-xl font-semibold gap-6">
                       
                         <span> Parking Lot Maintenance</span>
                         <span> Snow Services</span>
                         <span> Power Washing</span>
                         <span> Painting</span>
                    </div>
                </div>

                <div className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100  rounded-xl ">
                    
                   
                    <div id="Pest" className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>Pest Control</span>
                    <img src={require("../Assets/pest.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                </div>
                <div className=" flex md:flex-row flex-col justify-around md:gap-8 mb-6 text-color3 service-card p-8 m-8 lg:w-5/12 w-100  rounded-xl ">
                    
                   
                    <div id="locksmith" className="flex flex-col items-center gap-4 text-5xl font-bold">
                    <span>Locksmith</span>
                    <img src={require("../Assets/lock.jpg")} className="w-80 h-80 object-cover rounded-xl" />
                    </div>
                   </div>
           
            
                </div></div>
     );
}
 
export default AllServices;