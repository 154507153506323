import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainPage from './pages/MainPage';
import Services from './pages/Services';
import Error404Page from './pages/Error404Page';
import AllServices from './pages/AllServices';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainPage />}/>
          <Route  path="services" element={<AllServices />} />
          <Route path="*" element={<Error404Page />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
