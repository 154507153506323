import React from 'react';
import Services from './Services';
import Footer from './Footer';
import Header from './Header';
import About from './About';
const MainPage = () => {
    return ( 
     <>
    
     <Header />
     <Services />
     <About />
     <Footer /> 
     </>
     );
}
 
export default MainPage;