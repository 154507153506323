import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import bgImage from '../Assets/HeroVector.png'
import man from '../Assets/man1.jpg'
import Electrical from '../Assets/Electrical.jpg'
import Plumbing from '../Assets/Plumbing.jpg'
import wash from '../Assets/Power washing.jpg'
import glass from '../Assets/glass.jpg'
import { Card } from "./Card";

const Services = () => {
 
    return ( 
        <section id="services" className="services w-full h-auto flex flex-col items-center justify-center relative   lg:py-24  md:py-20 px-2 py-6">
                <img className="h-[50%] w-[80%] z-0  lg:h-[50vh] md:h-[50vh] lg:w-1/2 md:w-[55%] absolute left-0 bottom-0 rotate-180 opacity-50" src={bgImage} alt="Hero Background Vector" />
                <img className="h-[50%] w-[80%] z-0 lg:h-[50vh] md:h-[50vh] lg:w-1/2 md:w-[55%] absolute right-0 top-0 " src={bgImage} alt="Hero Background Vector" />
        
        <main className="w-full z-50 flex flex-col gap-3 items-center justify-center">
            <p className="font-light text-xl z-30 text-color3/80 tracking-widest">
                <Fade>Our Top</Fade>
            </p>
            <h2  className="md:text-6xl text-4xl z-30 font-medium capitalize text-color3">
                <Fade>SERVICES</Fade>
            </h2>
            <div className="w-full h-auto flex flex-wrap  m-auto  justify-center  lg:gap-7 md:gap-10 gap-7 my-12 z-20 px-8 md:px-0">
                
           
                       <div className="lg:w-3/12 md:w-2/6"> <Card  cardClass="w-full h-full bg-white flex flex-col gap-4 items-center justify-center py-12 cursor-pointer transition duration-300 shadow-xl px-5 rounded-xl cardPseudo after:bg-color2" imageWrapperClass="w-42 h-42 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={"vuiiub"} imageSrc={man} textWrapperClass="w-full flex flex-col items-center gap-2" key={1} >
                            <h4 className="md:text-2xl text-xl rounded font-bold text-color3">
                                HVAC/R
                            </h4>
                            <p className="md:text-md text-sm  font-light text-center text-color3">
                            Your go-to source for professional HVAC and Refrigeration services. Our skilled technicians are committed to providing top-notch heating, ventilation, and air conditioning solutions. Whether you require routine maintenance, repairs, or installations, we have the expertise to ensure optimal performance. Count on us for reliable HVAC/R services tailored to meet your specific needs.
                            </p>
                        </Card></div>
                     <div className="lg:w-3/12 md:w-2/6">   <Card cardClass="w-full h-full bg-white flex flex-col gap-4 items-center justify-center py-12 cursor-pointer transition duration-300 shadow-xl px-5 rounded-xl cardPseudo after:bg-color2" imageWrapperClass="w-42 h-42 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={"vuiiub"} imageSrc={Plumbing} textWrapperClass="w-full flex flex-col items-center gap-2" key={1} >
                            <h4 className="md:text-2xl text-xl rounded font-bold text-color3">
                                PLUMBING
                            </h4>
                            <p className="md:text-md text-sm  font-light text-center text-color3">
                            We redefine plumbing services with expertise and dedication. Our team of adept technicians is prepared to address a spectrum of plumbing needs, from routine maintenance to urgent repairs and seamless installations. We pride ourselves on delivering trustworthy and effective plumbing solutions, ensuring your contentment throughout the process. Entrust us with your plumbing requirements, and experience a level of professionalism and service that goes beyond the ordinary.
                            </p>
                        </Card></div>
                     <div className="lg:w-3/12 md:w-2/6">   <Card cardClass="w-full h-full bg-white flex flex-col gap-4 items-center justify-center py-12 cursor-pointer transition duration-300 shadow-xl px-5 rounded-xl cardPseudo after:bg-color2" imageWrapperClass="w-42 h-42 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={"vuiiub"} imageSrc={Electrical} textWrapperClass="w-full flex flex-col items-center gap-2" key={1} >
                            <h4 className="md:text-2xl text-xl rounded font-bold text-color3">
                                ELECTRICAL
                            </h4>
                            <p className="md:text-md text-sm  font-light text-center text-color3">
                            Your destination for top-tier professional electrical services. Our highly skilled team proficiently handles routine maintenance, urgent repairs, and advanced installations. With a commitment to excellence, we deliver reliable and efficient electrical solutions. Rely on us for a service experience that consistently exceeds expectations.
                            </p>
                        </Card></div>
                     <div className="lg:w-3/12 md:w-2/6">   <Card cardClass="w-full h-full bg-white flex flex-col gap-4 items-center justify-center py-12 cursor-pointer transition duration-300 shadow-xl px-5 rounded-xl cardPseudo after:bg-color2" imageWrapperClass="w-42 h-42 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={"vuiiub"} imageSrc={wash} textWrapperClass="w-full flex flex-col items-center gap-2" key={1} >
                            <h4 className="md:text-2xl text-xl rounded font-bold text-color3">
                                GENERAL MAINTENANCE
                            </h4>
                            <p className="md:text-md text-sm  font-light text-center text-color3">
                            Our skilled technicians are dedicated to addressing a wide range of maintenance and repair tasks, ensuring satisfaction with every project. From minor fixes to complex installations, we've got you covered. Experience the convenience of professional handyman services for all your needs.
                            </p>
                        </Card></div>
                     <div className="lg:w-3/12 md:w-2/6">   <Card cardClass="w-full h-full bg-white flex flex-col gap-4 items-center justify-center py-12 cursor-pointer transition duration-300 shadow-xl px-5 rounded-xl cardPseudo after:bg-color2" imageWrapperClass="w-42 h-42 relative z-10 before:content-[''] before:absolute before:top-3 before:right-3 before:w-16 before:h-16 before:bg-color2/30 before:-z-10 before:rounded-tl-3xl before:rounded-br-3xl" cover="object-cover" imageAlt={"vuiiub"} imageSrc={glass} textWrapperClass="w-full flex flex-col items-center gap-2" key={1} >
                            <h4 className="md:text-2xl text-xl rounded font-bold text-color3">
                            Door/Glass
                            </h4>
                            <p className="md:text-md text-sm  font-light text-center text-color3">
                            We understand the importance of having a safe, secure, and beautiful environment. That's why we offer a comprehensive range of door and glass services, from repair and replacement to installation and maintenance. We are committed to providing high-quality workmanship and exceptional customer service. We will work with you to find the perfect solution for your needs.                            </p>
                        </Card></div>
                    
            </div>
            <Link to="/services" className="bg-color2 rounded-full px-6 py-3 font-medium text-color3">View All Services</Link>
        </main>

    </section>
     );
}
 
export default Services;