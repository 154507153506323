import { Fade,Slide } from "react-awesome-reveal";
import bg from '../Assets/toolbox.jpg'
import logo from '../Assets/logo-no-bg.png'
import { Link } from "react-router-dom";
const Header = () => {

        const scrollToServices = () => {
          const element = document.getElementById('services');
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        };
    return ( 
    <section className="header w-full h-screen relative overflow-x-hidden flex justify-end ">
           <img src={bg} alt="Hero Image" className=" h-[100%]  w-[100%] opacity-60 object-cover " />

            <main className=" flex flex-col justify-start items-start md:gap-6 gap-3 lg:w-3/4  w-full lg:h-full h-auto  absolute top-0 left-0 lg:px-36 md:px-24 px-5  pt-6  ">
            

               
                     <Slide direction="left">
                       <img src={logo} alt="Hero" className=" lg:h-50  md:h-36  h-24 " />
                       </Slide>
                    <p className="text-color3 uppercase  md:text-4xl  text-2xl  font-normal" >
                        <Fade>Problems pull you back, Arrow Launches you forward</Fade>
                    </p>
                    <h1 className="title text-color3 md:text-7xl sm:text-5xl text-4xl font-bold">
                      <Fade>Arrow Maintenance</Fade>    
                    </h1>
                    <p  className="text-color3 md:text-4xl text-2xl  font-light">
                        <Fade>HVAC/R, PLUMBING, ELECTRICAL, GENERAL MAINTENANCE, AND MORE ...</Fade>
                    </p>
                    <div className="w-full flex md:justify-start justify-between mt-8 md:mt-3  items-center lg:gap-12 md:gap-6 gap-0">
                       
                        <div className="flex items-center lg:gap-6 gap-3 cursor-pointer" onClick={()=>scrollToServices()}>
                            <span className="relative flex h-14 w-14">
                                <span  className="animate-ping absolute inline-flex h-full w-full rounded-full bg-color2 opacity-75"></span>
                                <span className="relative flex justify-center items-center text-white rounded-full h-14 w-14 bg-color2">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
</svg>

                                </span>
                            </span>
                            <button  className="outline-none border-none">
                                View All Services
                            </button>
                        </div>

                    </div>
                
            </main>
        </section>
    );
}
 
export default Header;