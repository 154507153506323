import { Fade } from "react-awesome-reveal";

import { Accordion } from 'flowbite-react';

const About = () => {
    return (
        <section className=" w-full h-auto flex flex-col items-center justify-center relative bg-color2/10   lg:p-24 md:p-20 px-2 py-6">

<main className="w-full flex flex-col gap-3 lg:px-36 items-center justify-center">
    <p className="font-light text-xl z-30 text-color3/80 tracking-widest">
        <Fade>About us</Fade>
    </p>
    <h2  className="md:text-4xl text-2xl z-30 font-medium capitalize text-color3">
        <Fade>We extend beyond the typical corporate identity, embodying more than just a business entity.</Fade>
    </h2>
    <div className="w-full h-auto my-16 ">
    <Accordion className="border-none text-color3 bg-none hover:bg-none active:bg-none">
      <Accordion.Panel>
        <Accordion.Title className="bg-color2/20 hover:bg-color2/50 focus:ring-color2/60  active:ring-color2/60 focus:bg-color2/50 text-color3">Friendly and Reliable
</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 ">
          Our technicians undergo training to embody friendliness and reliability. We take pride in being recognized as the dependable professionals serving your local community.
          </p>
        
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title className="bg-color2/20 hover:bg-color2/50 focus:ring-color2/60 active:ring-color2/60 focus:bg-color2/50 text-color3">We are ready 24/7
</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 ">
          We are available around the clock, ready to address any requirements you may have. Your concerns will be promptly attended to, and we approach emergencies with the same urgency and care as if they were our own.
          </p>
         
        </Accordion.Content>
      </Accordion.Panel>
      <Accordion.Panel>
        <Accordion.Title  className="bg-color2/20 hover:bg-color2/50 focus:ring-color2/60 active:ring-color2/60 focus:bg-color2/50 text-color3">Rest assured</Accordion.Title>
        <Accordion.Content>
          <p className="mb-2 ">
          Our services are backed by the assurance of being licensed and insured, ensuring that every action we take is carried out with the highest standards of professionalism and accountability.
          </p>
         
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>


        </div>
</main>

</section> );
}
 
export default About;